.header {

    $h: &;
    @include trans;

    position: fixed;
    background: transparent;
    top: 0;
    width: 100%;
    z-index: 1000;

    &.sticky {
        background: $gray;
    }

    &__container {
        display: flex;
        height: 10vh;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        @include mq(sm) {
            height: 10vh;
        }
    }

    &__logo {
        a {
            height: 100%;
            position: relative;
            width: 6%;
        }

        img {
            z-index: 2000;
            position: absolute;
            height: 70%;
            width: auto;
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        transform: translateX(-100%);
        top: 0;
        bottom: 0;
        left: 0;
        background: $gray;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10vh;
        width: 100%;

        @include mq(sm) {
            width: 70%;
            position: static;
            background: transparent;
            flex-direction: row;
            transform: translateX(0);
            height: auto;
            padding-top: 0;
        }

        ul {
            display: flex;
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
            }
        }

        li {
            margin-bottom: 20px;
            font-size: 20px;
            text-align: center;

            @include mq(sm) {
                margin: 0 10px;
                font-size: inherit;
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    &__social {
        ul {
            display: flex;
        }

        li {
            margin-left: 20px;
        }
    }

    &__search {
        margin-left: 40px;
    }

    &__language {
        background: transparent;
        border: none;
        color: $white;
        outline: none;

        option {
            color: $yellow;
        }
    }

    &__form {
        margin-left: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__input {
        @include trans;

        width: 0;
        opacity: 0;
        position: absolute;
        border: none;
        outline: none;
        padding: 5px 10px;
        border-radius: 5px;
        z-index: -1;
        right: 120px;
        color: $black;

        @include mq(sm) {
            right: 14%;
        }

        &.-open {
            opacity: 1;
            z-index: 1;
            width: 140px;

            @include mq(sm) {
                width: 22%;
            }
        }
    }

    &__search-button {
        background: transparent;
        border: none;
        font-size: 24px;
        outline: none;
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 23px;
        margin-left: 15px;

        @include mq(sm) {
            display: none;
        }

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
            opacity: 1;
            left: 0;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
