.home {
    &__hero {
        height: 100vh;
        position: relative;
        z-index: 2;

        &__badge {
            position: absolute;
            z-index: 5;
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
            width: 90%;
            max-width: 500px;
            height: 0;
            padding-bottom: 30%;
            transform: translateY(5vh);

            @include mq(sm) {
                bottom: 5%;
                left: 0;
                right: 5%;
                top: auto;
                transform: translateY(0);
            }

            img {
                width: 100%;
            }
        }

        &__layer {
            width: 100%;
            height: 100vh;
            position: absolute;
            z-index: 0;
            bottom: 0;
            pointer-events: none;
            
            img {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    &__slider {
        margin-bottom: 0 !important;
        height: 100vh;
        background: $gray;

        .slick-dots {
            left: 10%;
            bottom: 2%;
            transform: translateX(-50%);
            z-index: 100;

            @include mq(sm) {
                left: 8%;
                bottom: 15%;
            }

            @include mq(md) {
                left: 14%;
                bottom: 15%;
            }

            .slick-active {
                button {
                    &:before {
                        opacity: 1;
                        font-size: 13px;
                    }
                }
            }

            li {
                button {
                    &:before {
                        opacity: 1;
                        color: $yellow;
                        font-size: 7px;
                    }
                }
            }
        }

        .slick-arrow {
            display: none!important;
        }
    }

    &__slider-mini {
        background: $white;

        &__wrapper {
            width: 300px;
            position: absolute;
            bottom: 5%;
            left:0;
            right: 0;
            margin: auto;
            z-index: 2;

            @include mq(sm) {
                left: auto;
                right: 5%;
                top:50%;
                transform: translateY(-50%);
            }
        }

        .slick-dots {
            left: 50%;
            bottom: -30px;
            transform: translateX(-50%);

            @include mq(sm) {
                left: 8%;
                bottom: -30px;
            }

            @include mq(md) {
                left: 14%;
                bottom: -30px;
            }

            .slick-active {
                button {
                    &:before {
                        opacity: 1;
                        font-size: 13px;
                    }
                }
            }

            li {
                button {
                    &:before {
                        opacity: 1;
                        color: $white;
                        font-size: 7px;
                    }
                }
            }
        }
    }

    &__slide {
        height: 100vh;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        background: $black;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 20%;
            background: linear-gradient(to top, rgba(0,0,0, .7), transparent)
        }

        &__main-image {
            position: absolute;
            opacity: .7;
            width: auto;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);

            @include mq(md) {
                height: auto;
                width: 100%;
                min-height: 100%;
            }
        }

        &__content {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: auto;
            z-index: 2;
            top: 12%;
            left: 5%;

            @include mq(sm) {
                width: 40%;
                left: 5%;
                top: 30%;
            }

            @include mq(md) {
                width: 40%;
                left: 12%;
                top: 30%;
            }
        }

        &__subtitle {
            margin-top: 20px;
            text-transform: uppercase;
            color: $white;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 2vh;
            }

            &:after {
                content: '';
                height: 4px;
                background: $yellow;
                position: absolute;
                top: 0;
                left: 0;
                width: 30px;

                @include mq(sm) {
                    width: 5%;
                }
            }
        }

        &__title {
            margin-top: 0;
            font-weight: 700;
            color: $white;
            margin-bottom: 20px;

            @include mq(sm) {
                font-size: 56px;
                margin-bottom: 3vh;
            }
        }

        &__button {
            background: $yellow;
            border: none;
            border-radius: 50px;
            color: $black;
            font-weight: 700;
            padding: 5px 25px;

            @include mq(sm) {
                padding: 1vh 5vh;
            }
        }

        &__container {
            
        }

        &__wrapper {
            position: relative;
        }

        &__image {
            height: 0;
            width: 100%;
            overflow: hidden;
            position: relative;
            padding-bottom: 60%;

            img {
                position: absolute;
                height: auto;
                width: 100%;
                min-height: 100%;
                left: 0;
                top: 0;
            }
        }

        &__group {
            padding: 2vh;
            background: $white;
            position: relative;
        }

        &__date {
            color: $black;
            margin-top: 0;
        }

        &__group-title {
            color: $black;
            margin-top: 0;
            font-weight: 700;
            font-size: 14px;

            @include mq(sm) {
                font-size: 1.2vw;
            }
        }

        &__price {
            color: $black;
        }

        &__decoration-yellow {
            display: none;
            position: absolute;
            top: -7%;
            left: -10%;
            z-index: -1;

            @include mq(sm) {
                display: block;
            }
        }

        &__decoration-white {
            display: none;
            position: absolute;
            top: 36%;
            right: 10%;
            z-index: -1;

            @include mq(sm) {
                display: block;
            }
        }
    }

    &__agendar {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 25vh;
        position: relative;
        flex-direction: column;
        margin-bottom: -35%;

        @include mq(sm) {
            margin-bottom: 0;
            flex-direction: row;
            padding-top: 5vh;
        }

        &__backlayer {
            z-index: 0;
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            bottom: 0;

            img {
                width: 100%;
                height: 100%;

                @include mq(sm) {
                    height: auto;
                }
            }
        }

        &__col {
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            @include mq(sm) {
                width: 40%;
                margin-left: 0;
                margin-right: 0;
            }

            &--right {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;;
                align-items: center;

                @include mq(sm) {
                    align-items: flex-end;

                }
            }
        }

        &__title {
            color: $yellow;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 20px;
            text-align: center;

            @include mq(sm) {
                font-size: 50px;
                margin-bottom: 4vh;
                text-align: left;
            }
        }

        &__text {
            margin-top: 0;
            margin-bottom: 4vh;
        }

        &__container {
            margin-top: 40px;

            @include mq(sm) {
                width: 60%;
                margin-top: 0;
            }
        }

        &__wrapper {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 2vh;
                padding-bottom: 2vw;
                border-bottom: 1px solid  $white;
            }
        }

        &__image {
            width: 15%;
            position: relative;

            img {
                width: 60%;
                height: auto;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__group {
            width: 80%;

            @include mq(sm) {
                width: 70%;
            }
        }

        &__group-title {
            margin-top: 0;
            color: $yellow;
            font-weight: 700;
        }

        &__calendar-group {
            position: relative;
        }

        &__calendar-title {
            color: $yellow;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            margin-bottom: 50px;

            @include mq(sm) {
                margin-bottom: 5vh;
                font-size: 2vw;
                text-align: left;
            }
        }

        &__calendar {
            height: 30vh;
            width: 100%;
            background: $white;
        }

        &__appoinments {
            display: flex;
            background: $lightestGray;
            margin-bottom: 20px;
            padding: 15px;

            @include mq(sm) {
                padding: 2vh;
                margin-bottom: 2vw;
            }
        }

        &__appoinments-image {
            width: 10vw;
            margin-right: 5%;
            overflow: hidden;
            position: relative;

            @include mq(sm) {

            }

            img {
                position: absolute;
                width: 100%;
                height: auto;
                padding-bottom: 100%;
            }
        }

        &__appoinments-title {
            margin-top: 0;
            margin-bottom: .5vh;
            color: $black;
            font-weight: 700;
        }

        &__appoinments-text {
            color: $darkGray;
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 12px;

            @include mq(sm) {
                margin-bottom: .5vh;
                font-size: .9vw;
            }
        }

        &__appoinments-button {
            color: $black;
            font-weight: 700;
            text-transform: uppercase;
            background: transparent;
            border: none;
            font-size: 14px;

            @include mq(sm) {
                font-size: 1vw;
            }
        }

        &__comments {
            display: flex;
            margin-bottom: 100px;

            @include mq(sm) {
                margin-bottom: 0;
            }
        }

        &__comments-image {
            margin-right: 2vw;
        }

        &__comments-text {
            color: $yellow;
            font-weight: 700;
            font-size: 14px;

            @include mq(sm) {
                font-size: 1.3vw;
            }

            span {
                color: $white;
            }
        }

        &__decoration-circle {
            position: absolute;
            left: -10%;
            z-index: -1;
            top: 7%;

            @include mq(sm) {
                top: 10%;
            }
        }

        &__decoration-white-square {
            position: absolute;
            right: -9%;
            top: -6%;
            opacity: .3;
        }

        &__decoration-yellow-square {
            position: absolute;
            left: -23%;
            z-index: -1;
            top: 35%;
            @include mq(sm) {
                top: 40%;
            }
        }
    }

    &__opiniones {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        z-index: 2;

        &:after {
            content: '';
            background: $yellow;
            position: absolute;
            top: 20%;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;

            @include mq(sm) {
                display: none;
            }
        }

        @include mq(sm) {
            margin-top: -7%;
        }

        &__backlayer {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__container {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            padding-top: 30%;
        }

        &__row {
            width: 100%;
            text-align: center;

            &--top {
                margin-bottom: 6vh;
            }

            &--bottom {
                display: flex;
                flex-direction: column;

                @include mq(sm) {
                    flex-direction: row;
                }
            }
        }

        &__heading-title {
            color: $gray;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 32px;

            @include mq(sm) {
                font-size: 5vmin;
            }
        }

        &__heading-subtitle {
            margin-top: 0;
            font-size: 26px;

            @include mq(sm) {
                font-size: 5vmin;
            }

        }

        &__column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            @include mq(sm) {
                width: 33.34%;
                padding: 0 3vw;
            }

            &:not(:last-child) {
                border-bottom: 1vmin dotted $gray;
                margin-bottom: 20px;
                padding-bottom: 20px;

                @include mq(sm) {
                    border-right: 1vmin dotted $gray;
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        &__column-image {
            height: 15vh;
            width: auto;

            img {
                width: auto;
                height: 100%;
            }
        }

        &__column-title {
            font-weight: 700;

            @include mq(sm) {
                font-size: 4vmin;
            }
        }

        &__column-subtitle {
            @include mq(sm) {
                font-size: 2.5vmin;
            }
        }

        &__text {
            margin-bottom: 20px;

            @include mq(sm) {
                font-size: 1.7vmin;
                margin-bottom: 7vw;
            }
        }

        &__button {
            border: none;
            background: $gray;
            color: $white;
            border-radius: 50px;
            padding: 10px 20px;

            @include mq(sm) {
                position: absolute;
                bottom: 0;
                padding: 1vmin 2vmin;
                margin-bottom: 2vw;
                font-size: 2vmin;

            }

        }
    }

    &__boss {
        @include trans;

        &__image {
            img {
                width: 100%;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 0;

            @include mq(sm) {
                padding: 1vw 0;
            }
        }

        &__name {
            color: $black;
            font-weight: 700;
            margin: 0;
        }

        &__position {
            color: $black;
            margin: 0;
        }

        &:hover {
            transform: scale3d(1.03,1.03,1.03);
            box-shadow: 0 10px 30px 1px rgba(0,0,0, .3)
        }
    }

    &__crew {
        background: $white;
        position: relative;
        z-index: 2;

        &__top-layer {
            width: 100vw;

            @include mq(sm) {
                margin-bottom: -12vmin;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            color: $gray;
            font-weight: 700;
            text-align: center;
            font-size: 28px;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 4vw;
                font-size: 3vw;

            }
        }

        &__container {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
        }

        &__col-left {
            @include mq(sm) {
                width: 34%;
            }
        }

        &__col-right {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include mq(sm) {
                width: 63%;
            }

            .home__crew__employee {
                width: 100%;

                @include mq(sm) {
                    width: 48%;
                }
            }
        }

        &__employee {
            @include trans;

            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 2vw;
            }

            &:hover {
                transform: scale3d(1.03,1.03,1.03);
                box-shadow: 0 10px 30px 1px rgba(0,0,0, .3)
            }
        }

        &__image {
            width: 100%;
            overflow: hidden;
            height: 0;
            position: relative;
            padding-bottom: 72.5%;

            img {
                position: absolute;
                width: 100%;
                height: auto;
                left:0;
                top:0;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 0;

            @include mq(sm) {
                padding: 1vw 0;
            }
        }

        &__name {
            color: $black;
            font-weight: 700;
            margin: 0;
        }

        &__position {
            color: $black;
            margin: 0;
        }
    }

    &__reviews {
        background: $lightGray;
        padding: 12vmin 0;
        overflow: hidden;

        &__container {
            display: flex;
            position: relative;
            flex-direction: column;

            @include mq(sm) {
                margin-left: 10%;
                width: 90%;
                flex-wrap: wrap;
                flex-direction: row;
            }
        }

        &__decoration-yellow-top {
            position: absolute;
            left: 50%;
            top: 30%;
            transform: translateX(-50%);

            @include mq(sm) {
                transform: translateX(0);
                top: -15%;
                right: 18.5%;
            }
        }

        &__decoration-yellow-bottom {
            display: none;

            @include mq(sm) {
                display: block;
                position: absolute;
                bottom: -37.5%;

            }
        }

        &__column {
            &--left {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 60px;
                position: relative;

                @include mq(sm) {
                    width: 40%;
                    padding-right: 8vmin;
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            &--right {
                width: 100%;

                @include mq(sm) {
                    transform: translateX(10%);
                    width: 60%;
                }
            }
        }

        &__comment {
            font-size: 4vmin;
            color: $black;
            font-weight: 700;

            &__emoji {
                position: absolute;
                right: 0;
            }
        }

        &__slider {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .slick-slide {
                margin-right: .5%;
            }
            .slick-arrow {
                display: none !important;
            }

            .slick-dots {
                bottom: -20%;
                left: 50%;
                display: flex;

                @include mq(sm) {
                    bottom: -15%;
                    left: 14%;
                }

                .slick-active {
                    button {
                        &:before {
                            opacity: 1;
                            font-size: 13px;
                        }
                    }
                }

                li {
                    button {
                        &:before {
                            opacity: 1;
                            color: $yellow;
                            font-size: 7px;
                        }
                    }
                }
            }
        }

        &__slide {
            max-height: 100%;
            min-height: 10vmin;
            background: $yellow;
            padding: 4vmin 3vmin;
            position: relative;
            min-height: 210px;

            @include mq(sm) {
                min-height: 46vmin;

            }
        }

        &__wrapper {
            i {
                font-size: 5vmin;
                margin-bottom: 2vmin;
            }
        }

        &__review {
            font-weight: 700;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 6vmin;
                font-size: 2.5vmin;
            }
        }

        &__reviewer {
            text-transform: uppercase;
            color: $black;
            font-weight: 700;
            position: absolute;
            bottom: 4vmin;
        }
    }

    &__galery {
        &__container {
            display: flex;
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
            }
        }

        &__column {
            &--main {
                width: 100%;

                @include mq(sm) {
                    width: 33.34%;
                }
            }
        }

        &__row {
            a {
                width: 100%;
            }
        }

        &__image {
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                min-height: 100%;
            }
        }
    }

    &__contacto {
        &__row {
            display: flex;

            &--top {
                display: flex;
                flex-direction: column;

                @include mq(sm) {
                    flex-direction: row;
                }
            }

            &--bottom {
                position: relative;
                background: $yellow;
                display: flex;
                flex-direction: column;
                height: 90vh;

                @include mq(sm) {
                    flex-direction: row;
                    height: 50vh;
                }
            }
        }

        &__column {
            background: $darkestGray;

            @include mq(sm) {
                height: 90vh;
            }

            &--top-left {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                padding: 40px;

                @include mq(sm) {
                    padding: 0 17vmin;
                    width: 50%;
                }

            }

            &--top-right {
                position: relative;
                width: 100%;
                @include mq(sm) {
                    width: 50%;
                }
            }

            &--bottom-left {
                background: $yellow;
                width: 100%;
                position: relative;
                height: 30vh;
                
                @include mq(sm) {
                    height: 50vh;
                    width: 66.66%;
                }
            }

            &--bottom-right {
                background: $yellow;
                width: 100%;
                height: 210px;
                

                @include mq(sm) {
                    height: 50vh;
                    width: 33.34%;
                }
            }
        }

        &__form-title {
            font-size: 7vmin;
            font-weight: 700;
            text-align: center;
            margin-top: 0;
            margin-bottom: 20px;

        }

        &__form-text {
            text-align: center;
            margin-bottom: 4vmin;
            padding-bottom: 4vmin;
            position: relative;
            font-size: 14px;

            @include mq(sm) {
                font-size: 2vmin;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 20vmin;
                height: 1px;
                background: $yellow;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__form-input {
            border: none;
            border-radius: 5px;
            padding: 5px 10px;
            margin-bottom: 15px;

            @include mq(sm) {
                margin-bottom: 2vmin;
                padding: 1vmin;
            }

            &::placeholder {
                color: $midGray;
                font-size: 12px;

                @include mq(sm) {
                    font-size: 14px;
                }
            }

            &--textarea {
                height: 100px;

                @include mq(sm) {
                    height: 20vmin;
                }
            }
        }

        &__form-button {
            background: $yellow;
            border: none;
            border-radius: 50px;
            padding: 1vmin 3vmin;
            color: $darkestGray;
            font-weight: 700;
            margin-left: auto;
            margin-right: auto;
            width: 35%;

            @include mq(sm) {
                width: 25%;
            }
        }

        &__image {
            height: 90vh;
            background: $black;

            img {
                opacity: .5;
                width: 100%;
                height: auto;
                min-height: 100%;
            }
        }

        &__logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }

        &__layer {
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            background: rgba(253,184,19,1);
            z-index: 1;
            pointer-events: none;
            mix-blend-mode: multiply;
            right: 0;
            height: 30vh;

            @include mq(sm) {
                right: 33.34%;
                height: 50vh;
            }
        }

        &__map {
            @include trans;

            height: 30vh;
            width: 100%;
            overflow: hidden;
            position: absolute;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                filter: grayscale(99%);
            }

            @include mq(sm) {
                height: 50vh;
            }
        }

        &__local {
            width: 100%;
            height: 50vh;
            position: relative;
            overflow: hidden;            

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: auto;
            }
        }

        &__location {
            @include trans;
            
            opacity: 0;
            pointer-events: none;
            height: 70vh;
            width: 100%;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;

            background: $yellow;
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
                height: 50vh;
            }

            &.-active {
                opacity: 1;
                pointer-events: all;
            }
        }

        &__wrapper {
            position: absolute;
            z-index: 2;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 10px;
            box-shadow: 0 5px 10px 5px rgba(0,0,0,.15);
            left: 10%;
            top: 54vh;
            padding: 20px 0;
            width: 80%;

            @include mq(sm) {
                width: auto;
                padding: 0 5vmin;
                height: 40vh;
                left: 58.5%;
                top: 5vh;
                transform: translateX(0);
            }
        }

        &__wrapper-title {
            color: $black;
            font-weight: 700;
            margin-top: 0;
            position: relative;
            margin-bottom: 20px;
            padding-bottom: 20px;

            @include mq(sm) {
                font-size: 2.5vmin;
                margin-bottom: 3vmin;
                padding-bottom: 3vmin;

            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 60%;
                height: 2px;
                background: $black;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__wrapper-text {
            color: $black;
            text-align: center;
            cursor: pointer;

            @include mq(sm) {
                font-size: 1.7vmin;
            }

            &.-active {
                font-weight: 600;
                color: $yellow;
            }
        }
    }
}
